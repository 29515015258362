
.path {
    transition: fill 0.1s ease;
    transition-delay: 0.1s;
  }
  
  .path:hover {
    fill: #8DCC69;
    transition-delay: 0s;
  }

  .path:hover + .centered-text {
    fill: #006027;
    visibility: visible;
    transition-delay: 0s;
  }
  
.centered-text {
    font-size: 50px;
    font-weight: bold;
    text-anchor: middle;
    fill: #8DCC69;
    visibility: hidden;
    pointer-events: none;
    transition-delay: 0.1s;
  }

  .selected {
    fill: #8DCC69;
    transition-delay: 0s;
  }

  .selected + .centered-text {
    visibility: visible;
    fill: #006027;
  }