.news {
    max-width: 1000px;
    margin: 90px auto 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 768px) {
        margin: 30px 10px 30px;
    }
}

h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate;
  }
  
  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
  }

.pagination-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.news h1 {
    color: #006027;
    text-align: center;
    margin: 0 20px 30px;
    @media (max-width: 768px) {
        margin: 0 20px 10px;
    }
}
.news .section {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Trzy kolumny */
    grid-gap: 40px; /* Odstępy między kafelkami */
    width: 100%;
    @media (max-width: 768px) {
        grid-gap: 20px;
    }
}

/* Dodane media queries */
@media (max-width: 900px) {
    .news .section {
        grid-template-columns: repeat(2, 1fr); /* Zmiana na dwie kolumny */
    }
}

@media (max-width: 600px) {
    .news .section {
        grid-template-columns: 1fr; /* Zmiana na jedną kolumnę */
    }
}

.news-item {
    /* Stylizacja każdego kafelka */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    text-align: left;
    max-width: 280px;
    /* border: 2px solid #006027; */
}

@media (max-width: 768px) {
    .news-item {
        max-width: 100%; /* Ważne, aby element kontenera mógł rozciągnąć się na pełną szerokość */
    }
}

.news-item h3 {
    margin-top: 4px;
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    font-weight: bold;
    -webkit-line-clamp: 2; /* Liczba wierszy dla h3 */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; /* Zmniejszenie odstępu poniżej tytułu */
    font-weight: 700;
    font-size: 20px;
    /* border: 2px solid red; */
}

.news-item p.date {
    margin-top: 6px;
    margin-right: 6px;
    text-align: end;
    font-size: 12px;
    color: #666;
    /* border: 2px solid red; */
}

.news-item p.news-desc {
    /* Stylizacja każdego kafelka */
    /* margin-top: 8px;  */
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Liczba wierszy dla p */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: #666;
    font-weight: 750 !important;
    font-size: 14px;
    line-height: 1.3;
    /* max-height: 70px; */
    /* border: 2px solid red; */
}

.news-item img {
    width: 100%;
    min-width: 280px;
    height: auto;
    /* max-height: 210px; */
    /* object-fit: cover; */
    border-radius: 15px;
    aspect-ratio: 4 / 3;
    object-fit: cover;
}

@media (max-width: 768px) {
    .news-item img {
        min-width: 0; /* Usunięcie minimalnej szerokości na mobilnych */
        max-width: 100%; /* Upewnienie się, że obrazek nie będzie większy niż kontener */
    }
}

.news-item-button-container {
    display: flex;
    width: 100%;
    margin-top: 8px;
    align-items: flex-start;
}

.news-item button {
    border: 2px solid #006027;
    padding: 10px 20px;
    background-color: white;
    color: #494a49;
    font-weight: bold;
    font-size: small;
    cursor: pointer;
    border-radius: 30px;
}

.news-item button:hover {
    background: #006027; /* Kolor tła dla aktywnego (najechanego) przycisku */
    color: white; /* Kolor tekstu dla aktywnego (najechanego) przycisku */
}


/* Stylizacje dla tytułu, daty, opisu i przycisku */
