/* HeroSection.css */
.aktywne-text-section {
    margin: 50px;
    @media (max-width: 768px) {
      margin: 60px 30px;
    }
  }
  
  .aktywne-text-section h1 {
    text-align: center;
    color: #006027;
    font-size: xx-large;
    margin-bottom: 40px;
  }

  .aktywne-text-section p {
    text-align: justify;
    margin: 20px auto;
    max-width: 1000px; 
    line-height: 1.5;
    color: #494A49;
  }
  
  /* Dodaj więcej stylów, jakie są potrzebne dla twojego komponentu */
  