/* ---------------- TITLE ---------------- */
.news-detail {
    max-width: 1000px;
    margin: 70px auto 70px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1020px) {
        margin: 10px 20px 0px;
    }
}
.news-detail h1 {
    color: #006027;
}

.contentHTML img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }

/* ---------------- DATE ---------------- */
.news-detail-date {
    text-align: left;
}
.news-detail-date p {
    color: #9d9d9d;
    margin: auto;
    line-height: 1.5;
}

/* ---------------- PICTURE ---------------- */
.news-detail-item {
    margin: 30px 0;
}
.news-detail-item img {
    max-width: 600px;
    max-height: 450px;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 15px;
    @media (max-width: 700px) {
        max-width: 100%;
    }
}
