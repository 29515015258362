.function-container-max-width {
    display: block;
}

.function-container {
    max-width: 1000px;
    margin: 70px auto 70px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1020px) {
        margin: 10px 20px 0px;
    }
}

.function-container h1 {
    color: #006027;
    text-align: center;
}

.function-container h3,
.function-container-green h3,
.function-container-column-content h3 {
    color: #70b742;
}

.function-container p,
.function-container-green p,
.function-container-column-content p {
    color: #494A49;
    text-align: left;
    line-height: 1.5;
    padding-bottom: 10px;
}

.function-container-green {
    background: #f0f9ea;
    /* border: 1px solid #494A49; */
}

.function-container-columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    @media (max-width: 1020px) {
        padding: 20px;
    }
}

.function-container-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 48%;
}

.function-container-column-content {
    align-items: left;
    padding-right: 20px;
}

.function-container-column-image img {
    max-width: 100%;
    height: auto;
    align-items: left;
}

@media (max-width: 1000px) {
    .function-container-column {
        flex-basis: 100%;
         /* Każda kolumna zajmuje 100% szerokości kontenera */
    }
}
