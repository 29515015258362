.tutorial {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px auto 70px;
    @media (max-width: 1020px) {
        margin: 30px 20px 30px;
    }
}

.tutorial h1 {
    color: #006027;
    text-align: center;
    margin: 0 20px 40px;
}

.tutorial .section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    width: 100%;
    @media (max-width: 1020px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
    }
}

.tutorial-item {
    display: flex;
    flex-direction: column;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-align: left;
    min-width: 260px;
    transition: background-image 0.3s ease;
}

.tutorial-item h3 {
    margin-bottom: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: normal;
}

.tutorial-item img {
    width: 100%;
    min-width: 280px;
    height: auto;
    max-height: 210px;
    object-fit: fill;
    border-radius: 15px;
    filter: brightness(0.5);
    transition: filter 0.2s ease;
    &:hover {
        filter: brightness(1.0);
    }
}

.thumbnail-container {
    position: relative;
}

.thumbnail-image {
    width: 100%;
    min-width: 280px;
    height: auto;
    max-height: 210px;
    object-fit: fill;
    border-radius: 15px;
    filter: brightness(0.5);
    transition: filter 0.3s ease;
}

.tutorial-item:hover .thumbnail-image {
    filter: brightness(1.0);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-content {
    padding: 20px;
    border-radius: 15px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
    z-index: 10000;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    z-index: 10001;
}

@media (max-width: 600px) {
    .modal-content {
        max-width: 90%;
        max-height: 90%;
    }
}

@media (min-width: 1024px) {
    .modal-content {
        width: 1000px;
        height: 500px;
    }
}