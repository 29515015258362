/* /* .account + * + *::before + *::after {
  margin: 0;
  padding: 0;
} 

.account p, 
.account ul, 
.account ol, 
/* .account li, 
.account figure, 
.account blockquote {
    margin: 0 !important;   
    padding: 0 !important;
    text-decoration: none !important;
/* } */

.account a ,
.account ul {
    text-decoration: none !important;
}

.account ul {
    list-style: none !important;
} 