
.aktywne-event-section {
    background: #afdb95;
    padding: 50px;
    text-align: center;
    @media (max-width: 768px) {
        padding: 60px 20px;
    }
  }
  
  .aktywne-event-section h1 {
    margin: auto;
    color: #494A49;
    font-size: xx-large;
  }

  .aktywne-event-section .columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.aktywne-event-section .icon{
    text-align: -webkit-center;
}

.aktywne-event-section .icon img {
    width: 150px;
    height: 150px;
    border-radius: 15px;
    object-fit: contain;
}

.aktywne-event-section .custom-icon img {
    width: 20%;
    height: auto;
    cursor: pointer;
    padding-top: 25px;
    transition: transform 0.2s ease; /* Dodajemy animację przesuwania */
}

.aktywne-event-section .custom-icon img:hover {
    transform: scale(1.1); /* lub inna wartość, aby powiększyć kolumnę po najechaniu */
}

.aktywne-event-section .text {
    color: #494A49;
    margin-top: 40px;
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Liczba wierszy dla p */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .aktywne-event-section .column {
        flex-basis: 100%;
         /* Każda kolumna zajmuje 100% szerokości kontenera */
    }
    .aktywne-event-section .icon img {
        width: 120px;
        height: 120px;
        border-radius: 15px;
    }

    .aktywne-event-section .custom-icon img {
        width: 178px;
    }
}

.aktywne-city-section .column:hover {
    transform: scale(1.2); /* lub inna wartość, aby powiększyć kolumnę po najechaniu */
}