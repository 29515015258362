.footer {
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    width: 100%;
    min-height: 100%;
    position: relative;
    margin-top: auto;
}

.footer-container-logo {
    max-width: 628px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.footer-logo {
    position: relative;
    top: 40px;
    width: 100%;
    height: auto;
}

.footer-image {
    width: 100%;
    height: auto;
}

.footer-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid white;
    color: white;
    text-align: center;
    font-size: 14px;
}

.contact-container {
    margin: 60px auto 20px;
}

.contact-column {
    text-align: left;
    /* border: 1px solid white; */
}

.contact-column h3 {
    font-size: 16px;
    text-align: left;
}

.contact-column a {
    font-size: 14px;
}

.contact-column p {
    font-size: 14px;
    text-align: left;
}

@media (max-width: 600px) {
    .contact-column h3 {
        font-size: 11px;
    }
    .contact-column a, .contact-column p {
        font-size: 11px;
    }
    .footer-container-logo {
        margin-right: 15px;
        margin-left: 15px;
    }
    .footer-info {
        font-size: 11px;
    }
}