.map-container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.map-container h1 {
  color: #006027;
  text-align: center;
  padding-bottom: 25px;
}

.search-fields-container {
  display: flex;
}

.map-container-green {
  background: #f0f9ea;
}

.map-container-columns-container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-basis: calc(50% - 20px);
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1000px) {
  .map-container-columns-container {
    flex-direction: column-reverse;
  }
}

.map-container-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: calc(50% - 20px);
}

.map-container-column-list {
  min-width: 100px;
  padding-right: 40px;
  padding-left: 20px;
  overflow: auto;
  max-height: 510px;
  border-radius: 15px;
  overflow-x: hidden;
}

.map-container-column-map {
  max-width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .map-container-column-map {
    text-align: center;
}
  
  .map-container-column-map svg {
      max-width: 80%;
      height: 350px;
  }
}

.map-container-column-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.map-container-column-list li {
  display: flex;
  height: 60px;
  width: 300px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #b0afaf;
  border-radius: 30px;
  background-color: white;
  align-items: center;
}

@media (max-width: 600px) {
  .map-container-column-list li {
    width: 250px;
  }

  .my-modal {
    width: 90%;
  }
}

.map-container-column-list li:hover {
  background-color: #bee2a9;
  border: 1px solid #006027;
  color: #006027;
}

.map-container-column-list li span:first-child {
  flex: 1;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 50px;
}

.map-container-column-list li span:last-child {
  flex: 4;
  color: #424242;
}

.map-container-column-list li span:last-child:hover {
  flex: 4;
  color: #006027;
}

.map-container-column-map img {
  max-width: 100%;
  height: auto;
  align-items: center;
}

.map-container-column-list::-webkit-scrollbar{
  width: 5px;
}

.map-container-column-list::-webkit-scrollbar-thumb{
background: #8DCC69;
border-radius: 15px;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  color: black;
}

.my-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  border-radius: 10px;
  outline: none;
}

.my-modal::after, .my-modal::before {
  content: '';
  display: block;
  position: sticky;
  left: 50%;
  height: 20px;
  width: 100%;
  background: #FFF;
}

.my-modal::before {
  top: 0;
}

.my-modal::after {
  bottom: 0;
}

.my-modal h2 {
  margin: 50px 40px 30px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid rgba(0, 69, 25, 0.5);
  color: #00421B;
}

.my-modal p {
  margin: 20px 40px;
  color: #494A49;
}

.my-modal p a {
  color: #8DCC69;
}

.my-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

li:hover {
  cursor: pointer;
}

::placeholder {
  color: black;
  opacity: 1;
}

:-ms-input-placeholder {
  color: black;
}

::-ms-input-placeholder {
  color: black;
}