/* Przyciemnione tło wokół modala */
.modal-overlay {
    position: fixed;
    inset: 0; /* Skrócona forma dla top: 0, left: 0, right: 0, bottom: 0 */
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Hierarchia */
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Dodanie płynności dla widoczności */
    opacity: 1;
    visibility: visible; /* Ustawienie widoczności */
}

/* Ukrycie overlay, gdy modal jest zamknięty */
.modal-overlay.hidden {
    opacity: 0;
    visibility: hidden; /* Ukrycie dla lepszej dostępności */
    pointer-events: none;
}

/* Stylizacja modala */
.modal {
    position: relative;
    padding: 1px;
    border-radius: 8px;
    /* background-color: white; Dodanie białego tła */
    max-width: 450px;
    width: 80%;
    max-height: 100%; /* Dodanie maksymalnej wysokości */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Efekt cienia */
    text-align: center;
    z-index: 1001;
    pointer-events: auto; /* Kliknięcia wewnątrz modala są możliwe */
}

/* Przycisk zamykania */
.close-button {
    position: absolute;
    top: -10px!important;
    right: -10px!important;
    background: none;
    border: none;
    font-size: 24px; /* Zmniejszono rozmiar dla lepszej estetyki */
    color: black; /* Zmieniono kolor na czarny, aby pasował do tła modala */
    cursor: pointer;
    z-index: 1002;
    transition: transform 0.2s ease; /* Dodanie efektu hover */
}

.close-button:hover {
    transform: scale(1.2); /* Powiększenie przycisku przy najechaniu */
}

/* Stylizacja obrazka reklamy */
.advertisement-image {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 8px; /* Zaokrąglone rogi dla lepszego wyglądu */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Dodanie lekkiego cienia */
}

/* Przykładowe stylowanie dla reszty strony */
.text {
    text-align: center;
}
