
/* Stylizacja głównego kontenera */
.combobox-container {
    position: relative; /* pozycjonowanie względem rodzica */
    width: 250px; /* szerokość całego komponentu */
    margin: 0 auto; /* wyśrodkowanie */
    font-family: Arial, sans-serif; /* czcionka */
    display: flex;
}
  
/* Stylizacja inputa */
.combobox-container input {
box-sizing: border-box;
width: 250px; /* szerokość na całą dostępną przestrzeń */
height: 60px; /* zwiększona wysokość ramki */
padding: 8px; /* odstępy wewnątrz inputa */
padding-left: 30px;
font-size: 16px;
border: 1px solid #c1c0c0; /* ramka inputa */
margin-bottom: 10px; /* odstęp od elementu poniżej */
}

.search-fields-container {
  display: flex;
}

.first-combobox input {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.combobox-container input:focus {
  outline: none; /* brak zaznaczenia na niebiesko */
}

/* Stylizacja listy rozwijanej */
.combobox-container select {
position: absolute;
top: 100%;
left: 0;
margin-top: 10px;
width: 100%; /* szerokość na całą dostępną przestrzeń */
padding-right: 20px; /* odstępy wewnątrz listy */
padding-left: 20px;
padding-bottom: 10px;
padding-top: 10px;
font-size: 16px;
border: 2px solid white; /* ramka listy */
border-radius: 10px; /* zaokrąglenie rogów */
background-color: white; /* kolor tła */
cursor: pointer; /* zmiana kursora na wskaźnik */
-webkit-appearance: none; /* Dla przeglądarek opartych na WebKit (Safari, Chrome) */
-moz-appearance: none; /* Dla przeglądarek opartych na Firefox */
appearance: none; /* Standardowa właściwość */
z-index: 1000;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
max-height: 300px; /* Ustaw na wartość, która Ci odpowiada */
overflow-y: auto;
}

  /* Możesz dodać pseudoelement, jeśli chcesz własną grafikę strzałki */
.combobox-container select::-ms-expand {
    display: none; /* Dla przeglądarek IE i Edge */
}
  
  /* Stylizacja opcji w liście */
.combobox-container select option {
padding: 10px; /* odstępy wewnątrz opcji */
background-color: white; /* kolor tła opcji */
color: #006027;
font-size: 15px;
font-weight: 500;
border-bottom: 1px solid #ccc; /* Linia oddzielająca */
}

.combobox-container select option:last-child {
  border-bottom: none; /* Usuwa linię oddzielającą */
}

/* Stylizacja wyświetlania wybranej opcji */
.combobox-container .selected-option {
margin-top: 10px; /* odstęp od elementu powyżej */
padding: 8px; /* odstępy wewnątrz */
border: 2px dashed #ccc; /* styl ramki */
border-radius: 4px; /* zaokrąglenie rogów */
}

.combobox-container select option:hover {
    background-color: initial; /* Jasny kolor tła */
    color: #8DCC69; /* Kolor tekstu */
  }

  /* Stylizacja koloru fontu placeholdera */
.combobox-container input::placeholder {
  color: #c1c0c0; /* kolor fontu placeholdera */
  font-size: 16px;
}

.combobox-container select::-webkit-scrollbar {
  width: 5px; /* szerokość paska przewijania */
}

.combobox-container select::-webkit-scrollbar-thumb {
  background: #e1e1e1; /* kolor paska przewijania */
  border-radius: 10px;
}

.combobox-container select::-webkit-scrollbar-thumb:hover {
  background: #555; /* kolor paska przewijania po najechaniu */
}