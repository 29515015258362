.aktywne-sponsors-section {
    margin: 50px 0 35px;
    text-align: center;
    overflow: hidden;
    @media (max-width: 768px) {
      margin: 60px 20px;
    }
  }
  
  .aktywne-sponsors-section h1 {
    margin: auto;
    color: #494A49;
    margin-top: 20px;
    font-size: medium;
  }

  .aktywne-sponsors-section .columns-container-strategiczny {
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}

.aktywne-sponsors-section .columns-container {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  
}

.aktywne-sponsors-section .column-strategiczny {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 50%; /* Dla równego podziału na cztery kolumny */
    transition: transform 0.2s ease; /* Dodajemy animację przesuwania */
}

.aktywne-sponsors-section .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    min-height: 120px;
}

.aktywne-sponsors-section .column-strategiczny:hover,
.aktywne-sponsors-section .column:hover {
    transform: scale(1.2); /* lub inna wartość, aby powiększyć kolumnę po najechaniu */
}

.aktywne-sponsors-section .icon  {
    padding-top: 10px;
    /* padding-bottom: 20px; */
    display: flex;
}

.aktywne-sponsors-section .icon img.mag {
  /* Stylizacja ikony (możesz użyć rzeczywistej ikony zamiast tekstu) */
  width: 60%;
}

.aktywne-sponsors-section .icon img {
    /* Stylizacja ikony (możesz użyć rzeczywistej ikony zamiast tekstu) */
    width: 80%;
    height: auto;
    object-fit: contain;
}

.aktywne-sponsors-section .icon.mag img {
  /* Stylizacja ikony (możesz użyć rzeczywistej ikony zamiast tekstu) */
  width: 60%;
}

.slider-content {
  padding: 20px 0 0;
}

/* Dodaj style dla slidera */
.slick-slider {
    width: 80%;
    margin: auto;
  }
  
  /* Dodaj style dla pojedynczego slajdu */
  .slick-slide {
    margin: 0 10px;
  }
  
  /* Dodaj style dla punktów kontrolnych (dots) */
  .slick-dots {
    bottom: -30px;
  }