.header-container {
    position: relative;
}

.navbar-container {
    max-width: 1068px;
    margin: auto;
    display: flex; 
    justify-content: center;
    /* Dodatkowe style dla wewnętrznego kontenera navbar */
}

.main-page-image {
    width: 100%;
    height: auto;
}

.main-page-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(8, 27, 9, 0.7); /* Zielona apka z przezroczystością */
    z-index: 1; /* Upewnij się, że jest na wierzchu obrazka */
  }

.header-image {
    width: 100%;
    height: auto;
    max-height: 125px; /* lub inna wartość, w zależności od potrzeb */
    /* Dodatkowe style dla obrazka */
}
