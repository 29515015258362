
.aktywne-int-section {
    background: linear-gradient(to top right, #8DCC69, #00501D);
    padding: 50px 100px;
    text-align: center;
    @media (max-width: 768px) {
      padding: 50px;
    }
}
  
.aktywne-int-section h1 {
    font-weight: normal;
    margin: auto;
    color: #ffffff;
    font-size: xx-large;
}

.aktywne-int-section .columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
    /* border: 1px solid red; */
}

.aktywne-int-section .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: top; */
    width: 200px;
    /* border: 1px solid red; */
}

.aktywne-int-section .icon img {
    /* Stylizacja ikony (możesz użyć rzeczywistej ikony zamiast tekstu) */
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #ffffff;
    /* border: 1px solid red; */
}

.aktywne-int-section .text {
    color: #8DCC69;
    font-weight: 700;
    font-size: 60px;   
}

.aktywne-int-section .stext {
    color: white;
    font-weight: 700;
    font-size: 15px;   
}

@media (max-width: 768px) {
    .aktywne-int-section .column {
        flex-basis: 100%;
         /* Każda kolumna zajmuje 100% szerokości kontenera */
    }
}