
.pagination {
    display: flex;
    justify-content: center; /* Wyśrodkowanie paginacji */
    list-style: none; /* Usunięcie stylów listy */
    padding: 0; /* Usunięcie domyślnego paddingu */
}

.pagination li {
    margin: 0 5px; /* Dodanie marginesu między przyciskami */
}

.pagination li a {
    display: block;
    padding: 5px 10px;
    text-decoration: none; /* Usunięcie podkreślenia linku */
    color: #494a49; /* Możesz dostosować kolor */
    /* border: 1px solid #ddd; Opcjonalna obramówka */
}

.pagination li a:hover {
    background-color: #eee; /* Kolor tła przy najechaniu */
}

.pagination li a.disabled {
    color: #ccc;
    pointer-events: none;
}

.pagination li.active a {
    font-weight: bold;
    color: #70b742;
}

/* Dodatkowe style dla strzałek i przycisków "Pierwsza" oraz "Ostatnia" */
.pagination li a:first-child, 
.pagination li a:last-child {
    font-weight: bold;
}
