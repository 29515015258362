
.aktywne-city-section {
    background: #f3f3f3;
    padding: 50px;
    text-align: center;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 60px 20px;
    }
  }
  
  .aktywne-city-section h1 {
    margin: auto;
    color: #494A49;
    font-size: xx-large;
  }

  .aktywne-city-section .columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.aktywne-city-section {
  text-align: -webkit-center;
}

.aktywne-city-section .icon img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    
}

.aktywne-city-section .rsp-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 90px;
}

.aktywne-city-section .custom-icon {
  text-align: -webkit-center;
}

.aktywne-city-section .custom-icon img {
    width: 20%;
    height: auto;
    cursor: pointer;
    padding-top: 25px;
}

@media (max-width: 768px) {
  .aktywne-city-section .column {
      flex-basis: 100%;
  }
  .aktywne-city-section .icon img {
    width: 120px;
    height: 120px;
  }

  .aktywne-city-section .custom-icon img {
    width: 178px;
  }
}

.slick-slider {
    width: 80%;
    margin: auto;
  }
  
  .slick-slide {
    margin: 0 10px;
  }
  
  .slick-dots {
    bottom: -30px;
  }

  .aktywne-city-section .stext {
    color: #494A49;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }