
.aktywne-app-section {
    margin: 50px;
    @media (max-width: 768px) {
        margin: 60px 20px;
    }
}

.aktywne-app-section h1 {
    margin: auto;
    color: #006027;
    font-size: xx-large;
  }

  .aktywne-app-section p {
    margin: auto;
    padding-top: 40px;
    line-height: 1.5;
    text-align: center;
    color: #494A49;
  }

.aktywne-app-section .column-container {
    max-width: 1000px;
    margin: auto;   
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aktywne-app-section .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 50%;
    @media (max-width: 768px) {
        text-align: center;
        flex-basis: 100%;
    }
}

.aktywne-app-section .column-image img {
    width: 100%;
    height: auto;
    object-fit: contain;
    /* border: 1px solid #494A49; */
}

.aktywne-app-section .column-content {
    padding: 20px;
    align-items: center;
    @media (max-width: 768px) {
        padding: 10px;
      }
}

.aktywne-app-section .column-content .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.aktywne-app-section .column-content .buttons button   {
    /* Stylizacja dla przycisków */
    background: none;
    /* padding-top: 50px; */
    /* gap: 10px; */
    margin: 10px 0;
    border: none;
    @media (max-width: 768px) {
        /* padding-top: 40px; */
      }
}

.aktywne-app-section .column-content .buttons button svg  {
    width: 140px;
    height: 40px;
    cursor: pointer;
}