
.aktywne-icon-section {
    margin: 50px;
    text-align: center;
    @media (max-width: 768px) {
        margin: 60px;
    }
  }

  .aktywne-icon-section .columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aktywne-icon-section .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; 
    flex-basis: 25%;
    padding: 20px;
}

.aktywne-icon-section .icon svg {
    margin-bottom: 20px;
}

.aktywne-icon-section .text {
    color: #494A49;
    font-weight: 700;
    font-size: 15px;   
}

@media (max-width: 768px) {
    .aktywne-icon-section .column {
        flex-basis: 100%;
    }
}