.search-bar-container {
    position: relative;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
}

.search-bar-container input {
    box-sizing: border-box;
    width: 100%;
    max-width: 250px;
    height: 45px;
    padding: 8px;
    padding-left: 15px;
    font-size: 16px;
    border: 1px solid #c1c0c0;
    margin-bottom: 10px;


}

@media (max-width: 600px) {
    .search-bar-container input {
        font-size: 12px;
        padding: 10px;
    }
}

.search-bar-container input::placeholder {
    color: black;
}

.suggestions-dropdown {
    position: absolute;
    background-color: white;
    width: calc(100% - 40px);
    border: 1px solid #ccc;
    z-index: 1;
    top: 100%;
    left: 0;
    margin-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    max-height: 300px;
    overflow-y: auto;
}

@media (max-width: 600px) {
    .suggestions-dropdown {
        padding-right: 10px;
        padding-left: 10px;
        width: calc(100% - 20px);
    }
}

.suggestions-dropdown div {
    padding: 10px;
    background-color: white;
    color: #006027;
    font-size: 15px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.suggestions-dropdown div:last-child {
    border-bottom: none;
}

.suggestions-dropdown div:hover {
    background-color: initial;
    color: #8DCC69;
}