.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: white;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

html, body {
  overflow-x: hidden;
}

.content {
  max-width: 1000px;
  margin: 70px auto 70px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1020px) {
      margin: 10px 20px 20px;
  }
}

.content h1 {
  color: #006027;
  text-align: center;
}

.content-text {
  text-align: justify;
  color: #494A49;
}

.content-text table {
  width: 100%;
  max-width: 100%;
}

.content-text td, .content-text th {
  width: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.floating-buttons {
  position: fixed;
  top: 320px;
  right: 30px;
  z-index: 1000;
}

.round-button {
  display: block;
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
  border-radius: 50%;
  color: white;
  border: 1px solid #FFF;
  background-color: #006027;
}

.floating-buttons2 {
  position: fixed;
  top: 260px;
  right: 0px;
  z-index: 1000;
}

.floating-buttons3 {
  position: fixed;
  top: 200px;
  right: 0px;
  z-index: 1000;
}

.cotrast-button {
  width: 80px;
  height: 40px;
  margin-bottom: 30px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  color: white;
  font-weight: bold;
  border: none;
  background-color: #006027;
}

.language-button {
  width: 40px;
  height: 40px;
  margin-bottom: 30px;
  color: white;
  font-weight: bold;
  border: none;
  background-color: #006027;
}

.language-button.selected {
  color: #006027;
  background-color: white;
  border: 2px solid #006027;
}

@media (max-width: 1200px) {
  .floating-buttons,
  .language-button,
  .cotrast-button {
    display: none;
  }
}

.language-button:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.cotrast-button.active {
  background-color: white;
  color: #006027;
  border: 2px solid #006027;
}


.high-contrast {
  background-color: black;
  color: white;
  fill: black;
}
.high-contrast h1,
.high-contrast .aktywne-int-section .text,
.high-contrast p,
.high-contrast .aktywne-icon-section .text,
.high-contrast .aktywne-event-section .text,
.high-contrast .function-container-column-content h3,
.high-contrast .help-item h3,
.high-contrast .samorzad-text,
.high-contrast .contact-section h2,
.high-contrast .contact-section h3,
.high-contrast .biznes-text,
.high-contrast .dropdown-menu a {
  color: white;
}
.high-contrast .aktywne-int-section,
.high-contrast .aktywne-event-section,
.high-contrast .aktywne-city-section,
.high-contrast .aktywne-sponsors-section,
.high-contrast .footer,
.high-contrast .function-container-green,
.high-contrast .samorzad-section,
.high-contrast .contact-section,
.high-contrast .contact-section .column,
.high-contrast .biznes-section,
.high-contrast .navbar,
.high-contrast .dropdown-menu {
  background: black;
}
.high-contrast .aktywne-city-section .custom-icon img,
.high-contrast .aktywne-city-section .icon img,
.high-contrast .aktywne-icon-section .icon svg,
.high-contrast .aktywne-sponsors-section .icon img,
.high-contrast .function-container-column-image img,
.high-contrast .tutorial-item img,
.high-contrast .header-image {
  filter: invert(1);
}
.high-contrast .news-item button,
.high-contrast .help-item button {
  background: black;
  color: white;
  border: 2px solid black;
}
.high-contrast .help-item {
  background: black;
}
.high-contrast .nav-items-mobile,
.high-contrast .nav-items-mobile a,
.high-contrast .nav-items-mobile .dropdown-toggle,
.high-contrast .nav-items-mobile .close-button {
  background-color: black;
  color: white;
}