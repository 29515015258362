
.navbar {
    margin: auto;
    position: absolute;
    width: 100%;
    display: flex;
    margin-top: 10px;
    z-index: 10;
    align-items: center;
    color: white;
    justify-content: space-between;
    align-items: center;
}

.navbar a {
    color: white;
    text-decoration: none;
}

.navbar-logo img {
    height: 100px;
    flex: 1;
    @media (max-width: 400px) {
        height: 80px;
    }
    @media (max-width: 320px) {
        height: 60px;
    }
    @media (max-width: 260px) {
        height: 40px;
    } 
}

.navbar .nav-item {
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.nav-items {
    list-style: none;
    display: flex;
    box-sizing: border-box;
}

.nav-item {
    margin-right: 20px;
}

.nav-item .dropdown-toggle {
    background: none; /* Usuwa tło przycisku */
    border: none; /* Usuwa obramowanie */
    color: inherit; /* Dziedziczy kolor tekstu */
    font: inherit; /* Dziedziczy styl czcionki */
    cursor: pointer; /* Dodaje kursor wskaźnik */
    padding: 0; /* Usuwa padding */
    text-transform: uppercase;
}

.nav-item .dropdown-toggle svg {
    margin-left: 5px;
}

.dropdown-menu {
    display: none;
    position: absolute;
    box-sizing: border-box;
    background-color: #f5f5f5; /* Białe tło */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); /* Dodatkowy cień dla efektu głębi */
    border-radius: 10px; /* Zaokrąglenie rogów */
    list-style: none;
    margin-left: 0;
    padding: 10px;
    /* background-color: #f9f9f9; */
    /* Styl dla rozwijanego menu */
}

.dropdown-menu li {
    margin: 0 16px;
}

.dropdown-menu li:first-child {
    border-bottom: 1px solid rgba(0, 80, 29, 0.5)
}

.dropdown-menu:after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
}

.dropdown-menu a {
    color: #006027; /* Ciemnozielony kolor dla niewybranych linków */
    text-transform: uppercase; /* Zamieniaj tekst na kapitaliki */
    padding: 12px 0;
    text-decoration: none;
    display: block;
    margin-left: 0; /* Resetowanie lewego marginesu */
    list-style: none;
}

.dropdown-menu a:hover {
    color: #8DCC69; /* Jasnozielony kolor dla wybranych linków */
}

.dropdown-menu a:not(:last-child) {
    border-bottom: 2px solid #8DCC69; /* Kreska pomiędzy linkami */
}

.nav-item.dropdown:hover .dropdown-menu,
.nav-item.dropdown:focus-within .dropdown-menu {
    display:block; /* Pokazuje menu podczas najechania myszką lub fokusu */
    margin-top: 10px; /* Dodaj odstęp między .nav-item a .dropdown-menu */
}

.hamburger-btn {
    display: none;
    z-index: 999;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-right: 20px;
    background: none;
    border: none;
    font-size: 30px;
    color: white;
}

@media screen and (max-width: 1200px) {
    .hamburger-btn {
        display: block;
    }
}

.nav-items-mobile {
    position: fixed;
    top: 0;
    left: 0; /* zmienione z -100% na 0 */
    width: 250px;
    height: 100%;
    background: linear-gradient(#367534, #00421B);;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    transform: translateX(-100%);
    animation: 0.3s ease-out 0s 1 slideInFromLeft;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.nav-items-mobile img {
    position: relative;
    width: 300px;
    left: -4%;
    margin-top: 20px;
}

.nav-items-mobile.open {
    transform: translateX(0);
}

.nav-items-mobile.closing {
    animation: 0.3s ease-out 0s 1 slideOutToLeft;
    transform: translateX(-100%);
}

.nav-items-mobile a:hover, .nav-items-mobile span:hover {
    color: #8DCC69;
}

.nav-items-mobile li {
    padding: 20px 0;
    width: 240px;
    margin: 0 0 0 -15px;
    list-style: none;
    border-bottom: 1px solid rgba(0, 69, 25, 0.5);
}

.nav-items-mobile li li {
    margin: 0 0 0 15px;
    width: 220px;
}

.nav-items-mobile li:last-child {
    border: none;
}

.nav-items-mobile .contact-column a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8DCC69;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.nav-mobile-overlay {
    display: none;
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 10; 
}

.nav-items-mobile {
    z-index: 20; 
}

@media (max-width: 1200px) {
    .nav-mobile-overlay {
        display: block;
    }
}