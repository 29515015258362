/* :where(.account) { */
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
/* } */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.shado-bottom-gradient {
  position: relative;
}

.shado-bottom-gradient::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px; /* Grubość cienia */
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.3) 0%, /* Kolor cienia na środku */
    rgba(0, 0, 0, 0) 40%   /* Przezroczystość na bokach */
  );
}
