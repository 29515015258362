.help {
    max-width: 910px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px auto 70px;
    @media (max-width: 1020px) {
        margin: 30px 20px 20px;
    }
}

.help h1 {
    color: #006027;
    text-align: center;
    margin: 0 20px 40px;
}

.help .section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    width: 100%;
    @media (max-width: 1020px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.help-item {
    /* Stylizacja każdego kafelka */
    display: flex; /* Użyj Flexboxa */
    flex-direction: column; /* Elementy ułożone pionowo */
    background-image: url("../assets/icons/background_nact.png");
    background-size: contain; 
    background-repeat: no-repeat; /* Zapobiega powtarzaniu obrazka */
    background-position: center;
    min-height: 312px;
    min-width: 260px;
    transition: background-image 0.3s ease; /* Dodaj płynny efekt przejścia */
    margin: 0px auto 0px;
    /* font-family: 'Lato', sans-serif; */
    font-weight: 700;
}

.help-item:hover {
    background-image: url("../assets/icons/background_act.png");
}

.help-item h3 {
    color: #006027;
    font-size: 2em;
    display: flex;
    justify-content: right;
    margin: 15px 10px 0;
}

.help-item p {
    margin: 0 0 0 18px;
    width: 145px;
    font-size: 85%; 
    line-height: 1.5;
    color: #494A49;
}

.help-item:hover p {
    color: #006027;
    font-weight: bold;
}


.help-item-button-container {
    display: flex;
    justify-content: center;
    width: 100%; /* Pełna szerokość kontenera */
    padding-bottom: 15px;
    margin-top: auto;
    /* border: 1px solid red; */
}

.help-item button {
    /* Stylizacja każdego kafelka */
    /* border: 1px solid #ccc; */
    background: transparent;
    border: 2px solid #006027;
    padding: 10px 20px;
    /* margin-top: 10px; */
    color: #494a49;
    font-weight: bold;
    font-size: small;
    cursor: pointer;
    border-radius: 30px;
}

.help-item button:hover {
    font-weight: bold;
    background: #006027; /* Kolor tła dla aktywnego (najechanego) przycisku */
    color: white; /* Kolor tekstu dla aktywnego (najechanego) przycisku */
}

.pagination-container {
    margin-top: 35px; /* Możesz dostosować wielkość marginesu według własnych preferencji */
}

.attachments-list a {
    display: block; /* Zapobiega dziwnym układom linków */
    margin: auto;
    max-width: 1000px;
    padding: 0 20px 10px;
}

