
.custom-slider {
    overflow: hidden;
    display: flex;
    width: 100%;
}

.slider-content {
    display: flex;
    width: 25%; /* Dostosuj zgodnie z liczbą slajdów */

    @media (max-width: 768px) {
        width: 50%;
    }
}

.slide {
    flex: 0 0 20%;
    transition: transform 0.5s ease-out;
}

.slider-content > div {
    flex: 0 0 100%; /* Aby każdy slajd zajmował pełną szerokość kontenera */
}

.prev-button, .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border: none;
    cursor: pointer;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}