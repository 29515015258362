
.contact-section {
    background: #c5e5b3;
    padding: 80px;
    text-align: center;
    @media (max-width: 1020px) {
        padding: 40px;
    }
}

.contact-section h1 {
    margin: auto;
    color: #494A49;
    font-size: xx-large;
}

.contact-section .columns-container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    @media (max-width: 1020px) {
        margin-top: 20px;
    }
}

.contact-section .column {
    display: flex;
    max-width: 300px;
    flex-direction: column;
    flex-basis: 40%;
    background-color: white;
    border-radius: 15px;
    margin: 20px;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding-left: 25px;
    padding-right: 25px;
}

.contact-section h2 {
    color: #494A49;
    margin-bottom: 5px;
    margin-top: 0px;
    font-weight: 800;
    font-size: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* border: 1px solid #494A49; */
}

.contact-section h3 {
    color: #006027;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 1px;
    font-size: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.contact-section p {
    color: #494A49;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    line-height: 30px;
}

.contact-section .column svg {
    border: 2px solid #006027;
    border-radius: 50%;
    scale: 80%;
    /* border: 1px solid #494A49; */
}